<template>
  <section class="video-player-controls">
    <b-button-group class="py-1 w-100">
      <b-button
        :style="{ backgroundColor: $store.getters.brandColor }"
        @click="$emit('toggleChapters')"
        style="border-radius:0 !important"
        class="mx-1"
        :disabled='videos.length <= 1'
      >
        <b-icon icon="list" />
        <span class="ml-2"> Chapters </span>
      </b-button>
      <b-button
        :style="{ backgroundColor: $store.getters.brandColor }"
        title="Loops"
        @click="$emit('toggleLoops')"
        v-if="loops.length > 0"
        class="mx-1"
      >
        <b-icon icon="list" />
        <span class="ml-2"> Loops </span>
      </b-button>
      <b-button
        :style="{ backgroundColor: $store.getters.brandColor }"
        :disabled="currentChapter === videos[0]"
        title="Previous"
        class="mr-1"
        @click="$emit('update:currentChapter', videos[videos.indexOf(currentChapter) - 1])"
      >
        <b-icon icon="skip-start-fill" />
      </b-button>

      <b-button
        title="Rewind"
        :style="{ backgroundColor: $store.getters.brandColor }"
        @click="_emit('changeTime',-15)"
        class="mr-1"
      >
        15 <b-icon icon="skip-backward-fill" class="ml-1"/>
      </b-button>

      <b-button
        :style="{ backgroundColor: $store.getters.brandColor }"
        title="Play"
        @click="$emit('update:isPlaying', !isPlaying)"
        class="mr-1"
      >
        <b-icon :icon="isPlaying ? 'pause-fill' : 'play-fill'" />
      </b-button>

      <b-button
        title="Skip Forward"
        class="mr-1"
        :style="{ backgroundColor: $store.getters.brandColor }"
        @click="_emit('changeTime',15)"
      >
        <b-icon icon="skip-forward-fill" class="mr-1"/>  15 
      </b-button>

      <b-button
        :style="{ backgroundColor: $store.getters.brandColor }"
        class="mr-1"
        :disabled="currentChapter === videos[videos.length - 1]"
        title="Next"
        @click="$emit('update:currentChapter', videos[videos.indexOf(currentChapter) + 1])"
      >
        <b-icon icon="skip-end-fill" />
      </b-button>
      <!-- <b-button
        title="Advanced"
        style="border-radius:0 !important"
        @click="$emit('update:showAdvanced', !showAdvanced)"
      >
        <b-icon
          :style="{
            color: showAdvanced ? $store.getters.brandColor : '',
          }"
          icon="gear-fill"
        />
        <span class="ml-2"> Advanced </span>
      </b-button> -->
    </b-button-group>
  </section>
</template>

<script>
export default {
  name: "VideoPlayerControls",
  components: {},
  mixins: [],
  props: {
    videos: {
      type: Array,
      required: true,
    },
    loops: {
      type: Array,
      required: true,
    },
    currentChapter: {
      type: Object,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    showAdvanced: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
